body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  color: #3a3a3a;
  font-weight: 400;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}

table {
  width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.fltL {
  float: left;
}

.fltR {
  float: right;
}

.padding_none {
  padding: 0 !important;
}

.border_none {
  border: none !important;
}

.navbar-toggle {
  background-color: #fff !important;
}

.icon-bar {
  background-color: #000 !important;
}

.p_right0 {
  padding-right: 0px !important;
}

.p_left0 {
  padding-left: 0px !important;
}

.Green {
  color: #50d21a;
}

.Red {
  color: #e21818;
}

.Blue {
  color: #139bbf;
}

.Yellow {
  color: #feca05;
}

.LoginArea {
  background-color: #dd5d59;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.LoginArea form {
  width: 100%;
}

.LoginArea .LoginBox {
  background-color: #ffffffab;
  padding: 35px;
  max-width: 90%;
  width: 500px;
  text-align: center;
  border-radius: 15px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  margin: 15px auto;
}

.LoginArea .LoginBox .errorImg {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.LoginBox.flex_box._verifciation {
  padding: 10px;
  max-width: 650px;
  width: 100%;
}

.LoginBox.flex_box._verifciation figure {
  background-color: #e5f5fd;
  width: 100%;
  max-width: 100%;
  padding: 30px 0;
  border-radius: 10px;
}

.LoginBox.flex_box._verifciation figure img {
  max-width: 100%;
  max-width: 200px;
}

.LoginBox.flex_box._verifciation img.approval_image {
  margin: 45px 0 15px;
}

.LoginBox.flex_box._verifciation .image_wrap p b {
  display: block;
  padding: 10px 0;
  font-size: 21px;
}

.LoginBox.flex_box._verifciation .image_wrap p {
  color: #0009;
}

img.approval_image {
  width: 146px;
  opacity: 0.8;
}

.flex_box .approval_image {
  width: 110px;
  margin-top: 20px;
}

.LoginArea .LoginBox figure a {
  padding: 0;
  border-radius: unset;
}

.LoginArea .LoginBox figure {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  /* border: 1px solid #aaa3; */
}

.LoginArea .LoginBox h5 {
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  color: #000;
  padding-bottom: 16px;
}

.LoginArea .LoginBox h6 {
  text-align: center;
  margin: 0 0 30px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  line-height: 19px;
}

.LoginArea .LoginBox .form-group {
  position: relative;
  margin: 0 0 20px 0;
}

.LoginArea .LoginBox .form-group label {
  font-weight: 400;
  color: #17222c;
  font-size: 14px;
  display: block;
  text-align: left;
  padding: 5px;
}

.LoginArea .LoginBox .form-group .form-control {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 50px;
  height: 40px;
  padding: 0 30px 0 43px;
  font-size: 14px;
}

.LoginArea .LoginBox .form-group .Icon {
  width: 34px;
  height: 34px;
  background-color: #d4d4d4;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
  position: absolute;
  left: 3px;
  z-index: 100;
  top: 34px;
  color: #000;
}

.LoginArea .LoginBox button,
.LoginArea .LoginBox a {
  border: none;
  padding: 10px 40px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 500;
  display: block;
  position: relative;
  background: #dd5d59;
  box-shadow: 0px 23px 36px #f36c6757;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  width: 100%;
  border-radius: 50px;
  text-decoration: none;
}

.LoginArea .LoginBox button:hover,
.LoginArea .LoginBox a:hover {
  background-color: #4971b2;
}

.Header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #e5f5fd;
  z-index: 99;
  display: flex;
  align-items: center;
}

.Header .Logo {
  width: 310px;
  background-color: #e5f5fd;
  text-align: center;
  padding: 5px 0;
  float: left;
  height: 65px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease-in-out;
}
.Header .Small-Logo {
  width: 50px;
  background-color: #e5f5fd;
  text-align: center;
  padding: 5px 0;
  float: left;
  height: 65px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all ease-in-out;
}

.Header .Logo img {
  width: 80%;
  /* height: auto; */
  /* max-width: 90px; */
}

.Header .Navigation {
  transition: all 0.2s ease;
  padding: 7px 1.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: right;
  height: 60px;
}

.Header .Navigation .Avater {
  margin: 5px 0 5px 30px;
  position: relative;
  display: inline-block;
}

.Header .Navigation .Avater a {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
}

.Header .Navigation .Avater a figure {
  width: 35px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  overflow: hidden;
}

.Header .Navigation .Avater ul {
  right: 0;
  left: auto;
  top: 100px;
  border: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease-in-out;
  position: absolute;
  box-shadow: 0 1px 4px #ddd;
  background-color: #fff;
}

.Header .Navigation .Avater ul li {
  margin: 0;
  border-bottom: 1px solid #d2d3d4;
}

.Header .Navigation .Avater ul li:first-child {
  background: #dd5d59;
  position: relative;
  padding: 15px 10px 15px 60px;
  text-align: left;
  border-bottom: 1px solid #fff;
}

.Header .Navigation .Avater ul li figure {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  border: 2px solid #fff;
  overflow: hidden;
}

.Header .Navigation .Avater ul li h4 {
  color: #fff;
  font-size: 17px;
  line-height: 18px;
}

.Header .Navigation .Avater ul li h4 span {
  font-size: 11px;
  font-weight: 400;
}

.Header .Navigation .Avater ul li a {
  color: #4e4e4e;
  padding: 10px 20px;
  font-weight: 400;
  transition: 0.5s all ease-in-out;
}

.Header .Navigation .Avater ul li a span {
  margin: 0 10px 0 0;
}

.Header .Navigation .Avater ul li a:hover {
  background-color: #dd5d59;
  color: #fff;
}

.Header .Navigation .Avater:hover ul {
  opacity: 1;
  visibility: visible;
  top: 115%;
}

.SidenavBar {
  height: calc(100% - 60px);
  position: fixed;
  width: 250px;
  top: 60px;
  padding: 0;
  overflow: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s all ease-in-out;
}

.SidenavBar-Small {
  height: calc(100% - 60px);
  position: fixed;
  width: 50px;
  top: 60px;
  padding: 0;
  overflow: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s all ease-in-out;
}

.SidenavBar::-webkit-scrollbar-track {
  background: #bbd5ff;
  border-radius: 5px;
}

.SidenavBar::-webkit-scrollbar-thumb {
  background-color: #4971b2;
  border-radius: 5px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  /* display: none; */
}

.SidenavBar::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
  /* display: none; */
}

.SidenavBar ul,
.SidenavBar-Small ul {
  margin: 0;
  padding-left: 0;
}

.SidenavBar ul li,
.SidenavBar-Small ul li {
  margin: 0;
}

.SidenavBar ul li a,
.SidenavBar-Small ul li a {
  display: block;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  color: #000;
  font-family: "Roboto";
  font-size: 12.2px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  padding: 16px 22px 11px 42px;
  font-weight: 400;
  font-family: "Poppins";
}

.SidenavBar-Small ul li a {
  padding: 16px 22px 11px 0px;
}

.SidenavBar ul li a span,
.SidenavBar-Small ul li a span {
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 14px;
  color: #000;
  transition: 0.5s all ease-in-out;
}

.SidenavBar ul li a:hover span,
.SidenavBar-Small ul li a:hover span,
.SidenavBar ul li a.active span,
.SidenavBar-Small ul li a.active span {
  color: #fff;
}

.SidenavBar ul li a.active,
.SidenavBar-Small ul li a.active,
.SidenavBar ul li a:hover,
.SidenavBar-Small ul li a:hover {
  background-color: #4971b2;
  color: #fff !important;
  border-color: #fff;
  font-weight: 400;
}

/* Mobile Sidebar */

.SidenavBar-mobile {
  background-color: #fff;
  /* height: calc(100% - 60px); */
  /* position: fixed; */
  width: 235px;
  /* top: 60px; */
  padding: 0;
  overflow: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.SidenavBar-mobile::-webkit-scrollbar-track {
  background: #bbd5ff;
  border-radius: 0;
}

.SidenavBar-mobile::-webkit-scrollbar-thumb {
  background-color: #4971b2;
  border-radius: 0;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

.SidenavBar-mobile::-webkit-scrollbar {
  width: 7px;
  border-radius: 0;
}

.SidenavBar-mobile ul {
  margin: 0;
  padding-left: 0;
}

.SidenavBar-mobile ul li {
  margin: 0;
}

.SidenavBar-mobile ul li a {
  display: block;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  color: #000;
  font-family: "Roboto";
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  padding: 16px 22px 13px 51px;
  font-weight: 400;
  font-family: "Poppins";
}

.SidenavBar-mobile ul li a span {
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 20px;
  color: #000;
  transition: 0.5s all ease-in-out;
}

.SidenavBar-mobile ul li a:hover span,
.SidenavBar-mobile ul li a.active span {
  color: #fff;
}

.SidenavBar ul li a label {
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10%;
  color: #000;
  transition: 0.5s all ease-in-out;
}

.SidenavBar ul li a:hover label,
.SidenavBar ul li a.active label {
  color: #fff;
}

.SidenavBar-mobile ul li a.active,
.SidenavBar-mobile ul li a:hover {
  background-color: #4971b2;
  color: #fff;
  border-color: #ffede2;
  font-weight: 500;
}

/* Sub menu */
.sub-menu ul,
.sub-menu-small ul {
  margin: 0;
  padding-left: 0;
}

.sub-menu ul li,
.sub-menu-small ul li {
  margin: 0;
}

.sub-menu ul li a,
.sub-menu-small ul li a {
  display: block;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  color: #000;
  font-size: 12.2px;
  text-transform: capitalize;
  position: relative;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  padding: 16px 20px 9px 0px;
  font-weight: 400;
  font-family: "Poppins";
}

.sub-menu ul li a {
  padding: 16px 20px 9px 42px;
}

.sub-menu ul li a span,
.sub-menu-small ul li a span {
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 14px;
  color: #000;
  transition: 0.5s all ease-in-out;
}

.sub-menu ul li a:hover span,
.sub-menu ul li a.active span,
.sub-menu-small ul li a:hover span,
.sub-menu-small ul li a.active span {
  color: #fff;
}

.sub-menu ul li a.active,
.sub-menu ul li a:hover,
.sub-menu-small ul li a.active,
.sub-menu-small ul li a:hover {
  background-color: #4971b2;
  color: #fff !important;
  border-color: #4971b2;
}

.circle-button {
  padding: 4px 10px 4px 9px;
  border-radius: 50%;
  height: 31px;
  width: 31px;
  background-color: #dd5d59;
  color: #fff;
}

.avatar .pharmacist-avatar:hover {
  border: 2px solid #dd5d59;
  transition: 0.5s all ease-in-out;
}

.avatar .pharmacist-avatar {
  border: 2px solid #fff;
  transition: 0.5s all ease-in-out;
}

.avatar .pharmacist-avatar.is-invalid {
  border-color: #dc3545;
}

.WrapperArea {
  margin-left: 250px;
  flex: 1 1 auto;
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  transition: 0.5s all ease-in-out;
}

.WrapperArea-Small {
  margin-left: 50px;
  flex: 1 1 auto;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  transition: 0.5s all ease-in-out;
}

.WrapperArea .box,
.WrapperArea-Small .box {
  border: 1px solid #8c9399;
  border-radius: 10px;
  padding: 35px 0px 35px 0px;
}

.WrapperArea .WrapperBox,
.WrapperArea-Small .WrapperBox {
  padding: 15px;
  background-color: #efefef;
  min-height: calc(100vh - 60px);
}

.Small-Wrapper {
  background-color: #fff;
  padding: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  /* overflow-x: auto; */
  /* transition: 0.5s all ease-in-out; */
  transition: 0.5s all cubic-bezier(0, 0.57, 0.41, 0.4);
}

body .DashboardFilter .Date button,
body input,
body .DashboardFilter .Date input {
  border-radius: 10px;
}

h4.Title {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 8px;
}

h4.Title a {
  margin-right: 10px;
  color: #000;
}

h4.Title a:hover {
  color: #000;
}

h4.Title button {
  background-color: #16212b;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 15px;
  float: right;
  margin: -5px 0 0 0;
}

h4.Title button:hover {
  background-color: #2e8cff;
}

.SearchBox {
  margin: -12px 0 0 0;
  display: flex;
  align-items: center;
}
.SearchBox1 {
  margin: 4px 0 0 0;
  margin-left: 697px; 
  display: flex;
  align-items: center;
}
body .form-control-dp {
  border-radius: 4px;
  appearance: auto;
  margin-top: 0px;
  margin-bottom: -1px;
  border: 1px solid #7ba6ed;
  font-weight: 300;
  height: 35px;
}
body .form-label-dp {
  margin-top: 15px;
  font-size: 15px;
}
.SearchBox form {
  margin: 0;
  display: -webkit-box;
  display: flex;
}

.SearchBox form input,
.SearchBox form select {
  margin: 0 0 0;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 0;
  outline: 0;
  padding: 0 20px;
}

.SearchBox form button {
  padding: 7px 30px;
  display: inline-block;
  background-color: #16212b;
  margin: 0;
  color: #fff;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  font-size: 14px;
  letter-spacing: 0.5px;
  border: none;
  outline: 0;
}

.SearchBox form button:hover {
  background-color: #2f8d76;
}

/* .SearchBox a {
    background-color: #DD5D59;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 15px;
    margin: 0 0 0 20px
}

.SearchBox a:hover {
    background-color: #DD5D59;
    color: #fff;
}
 */

.TableList table {
  border: 1px solid #dcdcdc;
  width: 100%;
  /* display: block; */
  overflow-x: auto;
}

/* .TableList table tr:nth-child(odd) {
    background-color: #f5f2f2
} */

.TableList table tr:hover {
  background: #b3d3fa45;
  transition: all 0.3s linear;
}

.TableList table tr th,
.TableList table tr td {
  padding: 4px 10px;
}

/* .TableList table tr th{background-color:#dcdcdc;font-weight:600;text-transform:capitalize;color:#000;font-size:14px;background-image:url(../images/sorting.png);background-repeat:no-repeat;background-position:96% 50%} */
.TableList table tr td {
  font-weight: 500;
  color: #000;
}

.TableList table tr td a:hover {
  color: #000;
}

.TableList table tr th:last-child,
.TableList table tr td:last-child {
  text-align: center;
  min-width: 105px;
}

.TableList table tr td:last-child a {
  color: #545454;
  margin: 0 5px;
  font-size: 14px;
  display: inline-block;
}

.TableList table tr td:last-child a:hover {
  color: #000;
}

.TableList table tr td figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #00a9a7;
  overflow: hidden;
}

.TableList table tr td figure img {
  width: 100%;
  height: 100%;
}

/* .TableList table tr td span {
    padding: 4px 10px;
    color: #fff;
    border-radius: 5px;
    width: auto;
    display: block;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
} */

.TableList table tr td a.Green {
  color: #37a713;
}

.TableList table tr td a.Red {
  color: #e21818;
}

.TableList table tr td a.Blue {
  color: #139bbf;
}

.TableList table tr td a.Yellow {
  color: #feca05;
}

.TableList table tr td span.Green {
  color: #37a713;
}

.TableList table tr td span.Red {
  color: #777777;
  padding-bottom: 0;
}

.TableList table tr td span.Red:hover {
  color: #ff675d;
}

.TableList table tr td span.Blue {
  color: #139bbf;
}

.TableList table tr td span.Yellow {
  color: #feca05;
}

.TableList table tr td span.green {
  background-color: #37a713;
}

.TableList table tr td span.blue {
  background-color: #139bbf;
}

.TableList table tr td span.red {
  background-color: #bb0f0f;
}

a,
i {
  transition: all 0.4s linear;
}

.TableList table tr td span.orange {
  background-color: #df9919;
}

.Pagination {
  margin: 30px 0 0 0;
}

.Pagination ul {
  text-align: right;
  flex-wrap: wrap;
}

.Pagination ul li {
  display: inline-block;
  margin: 0 2px;
  text-align: center;
}

.Pagination ul li a {
  padding: 4px 4px 4px 4px;
  border: 1px solid #ddd;
  width: 33px;
  display: block;
  font-size: 15px;
  color: #656363;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border-radius: 18%;
}

.Pagination ul li a:hover {
  background-color: #d0e1fd;
  color: #4971b2;
}

.Pagination ul li.active a {
  border-color: #4971b2;
  background-color: #4971b2;
  color: #fff;
  font-weight: 500;
}

.Pagination ul li:first-child a,
.Pagination ul li:last-child a {
  /* width: auto; */
  padding: 4px 4px;
}

.PasswordArea form {
  border-right: 1px solid #ddd;
  padding: 0 30px 0 0;
}

.PasswordArea form .form-group {
  margin: 0 0 25px 0;
}

.PasswordArea form .form-group label {
  font-size: 14px;
  color: #3c3c3c;
  text-transform: capitalize;
  font-weight: 500;
}

.PasswordArea form .form-group .form-control {
  margin: 0 0 0;
  border: 1px solid #ddd;
  box-shadow: none;
  border-radius: 5px;
  height: 36px;
}

.PasswordArea form button {
  padding: 8px 30px;
  background-color: #16212b;
  outline: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 0;
  cursor: pointer;
  border: none;
  outline: 0;
  transition: 0.5s all ease-in-out;
}

.PasswordArea form button:hover {
  background-color: #2e8cff;
}

.OrderArea {
  background-color: #fff;
  padding-top: 15px;
}

.OrderArea ul {
  padding: 0;
  border-bottom: 1px solid #ddd;
}

.OrderArea ul li {
  font-size: 15px;
  list-style: none;
  border: 1px solid #ddd;
  border-bottom: none;
}

.OrderArea ul li span {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  width: 200px;
  display: inline-block;
  position: relative;
  padding: 0 15px 0 0;
  margin: 0 15px 0 0;
  background-color: #f5f5f5;
  padding: 10px 20px;
}

ul.Multiple::-webkit-scrollbar-track {
  background: #bbb;
  border-radius: 0;
}

ul.Multiple::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

ul.Multiple::-webkit-scrollbar {
  width: 7px;
  border-radius: 0;
}

.ModalBox .modal-dialog {
  max-width: 450px;
  margin: 50px auto;
}

.ModalBox .modal-dialog .CloseModal {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #000;
  font-size: 15px;
  border-radius: 50%;
}

.ModalBox .modal-dialog .Decline {
  margin: 0;
}

.ModalBox .modal-dialog .Decline h3 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 22px;
  margin: 0 0 5px;
  text-align: left;
}

.ModalBox .modal-dialog .Decline p {
  font-size: 16px;
  font-family: "Roboto";
  color: #777;
  text-align: left;
}

.ModalBox .modal-dialog .Decline h4 {
  margin: 15px 0 0 0;
  text-align: right;
}

.ModalBox .modal-dialog .Decline h4 a {
  text-transform: capitalize;
  margin: 0 0 0 30px;
  font-size: 17px;
  color: #49a0d5;
  font-weight: 400;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.ModalBox .modal-dialog .Decline h4 a:hover {
  color: #000;
}

.ModalBox .modal-dialog .Category {
  padding: 10px 10px;
}

.ModalBox .modal-dialog .Category h3 {
  text-align: center;
  font-weight: 600;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  font-size: 23px;
}

.ModalBox .modal-dialog .Category .form-group {
  margin: 0 0 20px;
  position: relative;
}

.ModalBox .modal-dialog .Category .form-group label {
  display: block;
  font-weight: 500;
  color: #000;
  font-size: 15px;
  font-family: "Roboto";
}

.ModalBox .modal-dialog .Category .form-group .form-control {
  box-shadow: none;
  border: 1px solid #c3c3c3;
  height: 40px;
  font-size: 14px;
  color: #000;
  line-height: 22px;
}

.ModalBox .modal-dialog .Category .form-group textarea.form-control {
  height: auto;
}

.ModalBox .modal-dialog .Category button {
  padding: 10px 40px;
  display: inline-block;
  background-color: #16212b;
  margin: 0;
  color: #fff;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  font-size: 15px;
  letter-spacing: 0.5px;
  border: none;
  outline: 0;
}

.ModalBox .modal-dialog .Category button:hover {
  background-color: #000;
}

body button {
  outline: none;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

/* body button:hover {
    background-color: #DD5D59;
} */

/* .SearchBox .div_wrap button {
    background-color: #DD5D59;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 15px;
    margin: 0 0 0 20px;
    white-space: nowrap;
    border-radius: 10px;
    outline: none;
    border: none;
}

.SearchBox .div_wrap button:hover {
    background-color: #DD5D59;
} */

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

ul {
  list-style: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

user agent stylesheet ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

span.passwordHideShow {
  position: absolute;
  top: 39px;
  right: 5px;
  padding: 0 10px;
  background: transparent;
  cursor: pointer;
}

.LoginArea .button_wrapper {
  padding: 15px 0;
}

/* custome style  03/08/2022 */

.TableList table thead tr th {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #d6e6ff;
  color: #000;
  padding: 7px 10px;
  text-wrap: nowrap;
}

.TableList table tbody tr td {
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  vertical-align: middle;
  text-wrap: nowrap;
}

.TableList {
  padding-top: 15px;
}

.TableList tbody {
  border-top: 1px !important;
}

.TableList .form-check-input:checked {
  background-color: #dd5d59;
  border-color: #dd5d59;
  cursor: pointer;
}

body .form-switch .form-check-input {
  background-color: #00568b94;
  border-color: #e5f5fd;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.SearchBox .div_wrap input[type="text"] {
  min-height: 35px;
  padding: 5px 10px;
  border: 1px solid;
  outline: none;
  width: 100%;
}

.TableList table thead tr {
  border: 1px solid #dcdcdc;
  background-color: #dd5d59;
}

.TableList table tbody tr {
  border-bottom: 1px solid #dcdcdc;
}

.TableList table tr a {
  color: inherit;
}

.Pagination ul li.disabled a {
  cursor: not-allowed;
  background-color: #ebebeb;
}

.Pagination ul li.disabled a:hover {
  color: inherit;
}

.search_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 8px;
}

.TableList table tr td span.inline-block {
  display: inline-block;
}

.TableList table tr td i {
  font-size: 17px;
  cursor: pointer;
}

.PasswordArea .content_section {
  width: 100%;
  max-width: 500px;
  padding-top: 20px;
}

.PasswordArea .content_section .form-group {
  margin-bottom: 16px;
}

.PasswordArea .content_section .form-group label {
  margin-bottom: 9px;
}

.button,
button {
  border: none;
  padding: 0 40px;
  transition: 0.5s all ease-in-out;
  font-weight: 500;
  position: relative;
  background-color: #000;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 40px;
}

/* .button:hover,
button:hover {
    background-color: #000;
} */

body .form-control {
  border-radius: 4px;
  appearance: auto;
}

/* ********************** Modal ************************ */

body .modal-header {
  border-bottom: 0;
  position: relative;
}

body .modal-header .btn-close {
  margin: 0;
  position: absolute;
  right: 7px;
  top: 5px;
}

body .modal-header .modal-title {
  margin: 0 auto;
}

body .modal-footer {
  text-align: center;
  justify-content: center;
  border-top: 0;
}

body .modal-body {
  text-align: center;
}

body .modal-content {
  border-radius: 17px;
  border: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

body .modal-body .form-group {
  text-align: left;
  padding-bottom: 15px;
}

body .modal-body .form-group label {
  margin-bottom: 5px;
}

.OrderArea h6 {
  text-transform: capitalize;
}
.Orange {
  color: #f57f20;
}

.border-box {
  border-radius: 18px;
  margin-bottom: 20px;
  border: 1px solid #9b9191;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}

.SearchBox a {
  display: flex;
  align-items: center;
  border-radius: 6px;
  text-decoration: none;
}

.SearchBox a i {
  margin-right: 10px;
}

/* 25 nov */
img.img-fluid.logo_class {
  width: 110px;
  margin-bottom: 20px;
}

/* 30 nov */
span.form-check.form-switch {
  float: right;
}

.div_wrap {
  margin-right: 20px;
}

.refresh_btnn {
  border: 1px solid #dd5d59;
  background-color: #dd5d59;
  border-radius: 6px;
  padding: 9px 14px !important;
  color: #fff;
  font-size: 17px !important;
}

.menu_button {
  display: inline-block;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.menu_button .close {
  display: none;
}

.menu_button .open {
  display: block;
}

.menu_button i {
  width: 30px;
}

.mobile_open .menu_button .open {
  display: none;
}

.mobile_open .menu_button .close {
  display: block;
}

/* 
**************************************************************************************
                        Media 
*************************************************************************************** */

@media (min-width: 992px) {
  .menu_button {
    display: none;
  }

  .overlay {
    display: none;
  }

  .dashboard_home .DashboardFilter .SearchBox {
    margin: 15px 0 0;
  }

  .dashboard_home .DashboardFilter .SearchBox .div_wrap input {
    padding: 5px 10px;
    border: 1px solid #aaa;
  }
}

@media (max-width: 1200px) {
  h4.Title {
    margin-bottom: 20px;
  }

  .search_wrap {
    flex-direction: column-reverse;
  }
}

@media (max-width: 991px) {
  .SidenavBar {
    transition: all 0.3s linear;
    position: absolute;
    transform: translateX(-100%);
    background: #fff;
    z-index: 99;
  }

  .mobile_open .SidenavBar {
    transform: translateX(0);
  }

  .WrapperArea {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .WrapperArea .WrapperBox {
    height: auto;
    padding: 15px 7px;
  }

  .overlay {
    width: 100%;
    height: 100vh;
    background-color: #000;
    position: fixed;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transition: all 0.4s linear;
  }

  .mobile_open .overlay {
    visibility: visible;
    opacity: 0.6;
  }
}

@media (max-width: 767px) {
  .LoginBox.flex_box._verifciation {
    max-width: 90%;
  }

  .LoginBox.flex_box._verifciation img.approval_image {
    margin: 60px 0 25px;
    width: 100px;
  }

  .LoginBox.flex_box._verifciation figure img {
    max-width: 140px;
  }

  .TableList {
    width: 100%;
    overflow-x: auto;
  }
}

@media (max-width: 575px) {
  .Header {
    justify-content: space-between;
  }

  .Header .Logo {
    width: 100%;
    max-width: 100px;
  }

  .Header .Navigation {
    /* width: auto; */
    margin: 0;
  }

  .SearchBox {
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
  }

  .SearchBox .div_wrap {
    width: 100%;
    margin: 10px 0;
  }

  .SearchBox .Small-Wrapper {
    padding: 20px 15px;
  }

  .SearchBox .div_wrap input {
    width: 100%;
    min-height: 35px;
    padding: 5px 10px;
    border: 1px solid;
    outline: none;
  }

  .SearchBox button {
    margin-left: 0;
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .Header .Logo img {
    width: 100%;
  }
  .WrapperArea .box {
    margin: 10px 0px 10px 0px;
  }
  .expandable {
    display: none;
  }
  .Navigation {
    justify-content: end !important;
  }
}

@media (max-width: 575px) {
  .customer_details .innner {
    flex-wrap: wrap;
  }

  .customer_details .innner .user_image {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .customer_details .innner ul {
    padding-left: 0;
  }

  .Small-Wrapper {
    padding: 15px;
  }
  .WrapperArea .box {
    margin: 10px 0px 10px 0px;
  }
  .expandable {
    display: none;
  }
  .Navigation {
    justify-content: end !important;
  }
}

@media only screen and (max-width: 375px) {
  .log_in_page {
    display: block;
  }

  .text_governance {
    width: 100%;
    height: 50vh;
  }

  .log_in_form {
    width: 100%;
    height: 50vh;
    padding-top: 20px;
  }

  .button_wrap span {
    font-size: 28px;
  }

  .company_bottom_text p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .image_set {
    width: 50%;
    height: 40px;
    margin-bottom: 20px;
  }

  .image_right {
    width: 20%;
    margin: auto;
  }

  .form_wrapper form h3 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .wrapper_log_in_page .form_field .input {
    margin-bottom: 10px;
  }

  .wrapper_log_in_page .form_fields {
    padding-bottom: 15px;
  }
  .expandable {
    display: none;
  }
  .Navigation {
    justify-content: end !important;
  }
}

/* ****************************** Custom Style ************************* */

.student_details .inner {
  width: 100%;
  display: flex;
  align-items: center;
}

.student_details {
  width: 100%;
  padding: 50px 0 20px;
}

.student_details .innner {
  display: flex;
}

.student_details .innner .user_image {
  width: 100%;
  max-width: 130px;
  height: 130px;
  border: 3px solid #dd5d59;
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
}

.student_details .innner .user_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.student_details .innner ul li {
  margin-bottom: 10px;
}

.student_details .innner ul li strong {
  display: inline-block;
  font-weight: 600;
  width: 91px;
}

.student_details .innner ul {
  padding-left: 50px;
}

.student_details .inner {
  width: 100%;
  display: flex;
  align-items: center;
}

.course_details {
  width: 100%;
  padding: 20px 0 20px;
}

.course_details .innner {
  display: flex;
}

.course_details .innner ul li {
  margin-bottom: 10px;
}

.course_details .innner ul li strong {
  display: inline-block;
  font-weight: 600;
  width: 200px;
}

body .button,
body button {
  background-color: #dd5d59;
  height: auto;
  width: auto;
  padding: 4px 16px;
}

.main-heading {
  background: #dd5d59;
  color: #fff;
  padding: 9px;
  border-radius: 4px;
}

.text-editor-section {
  z-index: 0 !important;
  position: relative;
  min-height: 16rem;
  /* border: 1.5px solid #DD5D59; */
  border-radius: 4px;
  padding: 8px;
  box-shadow: rgb(9 30 66 / 15%) 0px 4px 8px -2px,
    rgb(9 30 66 / 8%) 0px 0px 0px 1px;
}

.text-editor-section.is-invalid {
  border: 1px solid #dc3545;
}

.rdw-editor-toolbar {
  /* border: 1.5px solid #DD5D59 !important; */
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
  border-radius: 5px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding: 7px 14px 7px 60px !important;
  border-radius: 10px !important;
}
.is-invalid .form-control {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e); */
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root input {
  padding: 7px 14px 7px 10px !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px !important;
}
.superTag {
  color: #000;
}

.css-e39x9u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
}
.remove-ul,
.remove-ul li {
  list-style: disc;
  color: #000;
}
.remove-title {
  font-size: 1.15rem;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23) !important;
} */
.password-eye-icon {
  position: absolute;
  top: 44%;
  right: 1%;
  cursor: pointer;
}
.td_xSmall_size {
  width: 80px;
  word-break: break-all;
}
.td_small_size {
  width: 120px;
  word-break: break-all;
}
.td_medium_size {
  width: 170px;
  word-break: break-all;
}
.td_large_size {
  width: 220px;
  word-break: break-all;
}
.td_XLarge_size {
  width: 260px;
  word-break: break-all;
}
.td_XXLarge_size {
  width: 360px;
  word-break: break-all;
}

/* custom drawer css */
.custom-drawer-small {
  width: 30rem !important;
}
.custom-drawer-medium {
  width: 45rem !important;
}
.custom-drawer-large {
  width: 60rem !important;
}

/* bar css */
::-webkit-scrollbar-track {
  background: #bbd5ff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #4971b2;
  border-radius: 10px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

.css-p0rm37 {
  top: -8px !important;
}
.css-1pq73ej .MuiOutlinedInput-root {
  padding: 0px !important;
}

.mui-date-picker input {
  padding: 0.47rem 0.75rem !important;
}
.mui-auto-complete input {
  padding: 0 !important;
}
.mui-date-picker fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.mui-date-picker.is-invalid fieldset {
  border-color: #dd5d59 !important;
}
.mui-date-picker p,
.mui-auto-complete p {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  font-family: "Poppins";
  margin-left: 0;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

/* Main css */

.primary-bg {
  background-color: #dd5d59;
}
.secondary-bg {
  background-color: #4971b2;
}

.primary-text {
  color: #dd5d59;
}
.secondary-text {
  color: #4971b2;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  /* border-color: #f7425669 !important; */
  border-color: #4971b2c0 !important;
  outline: 0;
  /* box-shadow: 0 0 0 1px rgb(247 66 86 / 41%) !important; */
  box-shadow: 0 0 0 1px rgba(66, 123, 247, 0.41) !important;
  transition: 0.1s all ease-in-out;
}
.banner-upload-image {
  border: 1px solid #fff;
}

.banner-upload-image:hover {
  border: 1px solid #4971b2c0;
  transition: 0.5s all ease-in-out;
  border-radius: 4px;
}
.banner-upload-image.is-invalid {
  border: 1px solid #dd5d59;
  border-radius: 4px;
}

.styles_playButton__1Q3EF::after {
  border-left-width: 15px !important;
  left: 56% !important;
}
.styles_playButton__1Q3EF {
  background-color: #0f16225e !important;
  height: 52px !important;
  top: -5px !important;
  margin-top: 0 !important;
}
.fancy-video {
  user-select: none;
  width: 44px;
  height: 44px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.react-select.is-invalid div {
  border-color: #dc3545 !important;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e"); */
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

/* react date picker */
.react-datepicker-wrapper svg {
  padding: 0 !important;
  right: 6%;
  top: 26%;
}
.react-datepicker__header {
  background-color: #e0f5fd !important;
}

/* file upload */
#FileUpload {
  display: flex;
  justify-content: center;
}
.upload-wrapper {
  margin: 6px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
}

/* === Upload Box === */
.upload {
  margin: 10px;
  height: 100px;
  border: 4px dashed #e0f5fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.upload.invalid {
  border: 4px dashed #dc3545;
}
.upload p {
  margin-top: 12px;
  line-height: 0;
  font-size: 22px;
  color: #000;
  letter-spacing: 1.5px;
}
.upload__button {
  background-color: #e0f5fd;
  border-radius: 10px;
  color: #000;
  padding: 0px 8px 0px 10px;
}
.upload__button.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.upload__button:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* === Uploaded Files === */
.uploaded {
  margin: 10px;
  background-color: #e0f5fd;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.file {
  display: flex;
  flex-direction: column;
}
.file__name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 300px;
  color: #000;
  font-size: 15px;
  letter-spacing: 1.5px;
}

/* react text editor  */
.rdw-image-modal-btn {
  margin: 0 5px !important;
  width: 88px !important;
  height: 30px !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  background: #dd5d59 !important;
  text-transform: capitalize !important;
}

.rdw-image-modal {
  width: 418px !important;
}

.mui-badge span {
  padding: 0;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker__close-icon::after {
  display: block !important;
  height: 23px !important;
  width: 23px !important;
  padding-top: 2px !important;
  padding-left: 1px !important;
  line-height: 1.3 !important;
  font-size: 16px !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.disabled-link button svg {
  color: grey;
  cursor: not-allowed;
}

.multi_search_date {
  padding: 3px 8px !important;
}

.avatar_wrapper {
  position: relative;
  z-index: 10;
}
.view_icon {
  position: absolute;
  bottom: 20px;
  right: 150px;
  border-bottom-left-radius: 20px;
  z-index: 20;
}
.upload_icon {
  position: absolute;
  bottom: 20px;
  left: 150px;
  border-bottom-left-radius: 20px;
  z-index: 20;
}

.location-icon {
  cursor: pointer;
  color: blue;
}

.custom-popup {
  position: absolute;
  top: 50px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


